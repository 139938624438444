<template>
    <chartBarras v-loading="loadingGrafica" :chart="chart_credito" />
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
import {mapGetters} from 'vuex'

let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

Vue.component('echarts',echarts);
let bgColor = '#fff', fontColor = '#666';
let lineOption = {
    lineStyle: {
        color: 'rgba(151,151,151,0.5)',
        type: 'solid'
    }
}
export default {

    data(){
        return {
            loadingGrafica: false,
            chart_credito : {
                dataset: {
                    dimensions: ['dia', 'value'],
                    source: []
                },
                backgroundColor: bgColor,
                tooltip:{
                    trigger: 'axis',
                    formatter: params => {
                        const data = params[0].data
                        const color = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#FF1720;"></span>`
                        return `${color} ${data.dia} - ${this.separadorNumero(data.value)}`
                    }
                },
                grid: {
                    top: '5%',
                    right: '5%',
                    /* left: '5%', */
                    /* bottom: '5%' */
                },
                /* value: 'Monday', */
                // name: '2 Ago. - 8 Ago.',
                xAxis: [{
                    type: 'category',
                    nameTextStyle: {
                        color: '#6D6B6B',
                        align: 'right',
                        verticalAlign: "top",
                        lineHeight: 33
                    },
                    axisLine: lineOption,
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: fontColor,
                        margin:20,
                        textStyle: {
                            fontSize: 14
                        },
                    },
                }],
                yAxis: [{
                    // name: '10 Feb. - 16 Feb.',
                    nameLocation: 'start',
                    nameTextStyle: {
                        color: '#6D6B6B',
                    },
                    axisLabel: {
                        formatter: (value) => {
                            return `${this.moneda.sigla} ${this.separadorNumero(value)}`
                        },
                        color: fontColor,
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: lineOption,
                    splitLine: lineOption
                },{
                    axisLine: lineOption,
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show:false
                    }
                }],
                series: [{
                    type: 'bar',
                    // data: sData,
                    barWidth: '30px',
                    itemStyle: {
                        normal: {
                            color: colorGeneral2
                        }
                    }
                }]
            }
        }
    },
    computed:{
        ...mapGetters({
            graficaDosMeses: 'almacen/credito/graficaDosMeses',
            moneda:'cedis/moneda',
        }),
    },
    watch:{
        graficaDosMeses(val){
            if(val.length > 0){
                this.chart_credito.dataset.source = val
            }else{
                this.chart_credito.dataset.source = []
            }
        },
    },

    mounted(){
        this.recargarGrafica()
    },
    methods: {
        recargarGrafica(){
            if(this.graficaDosMeses.length > 0){
                this.chart_credito.dataset.source = this.graficaDosMeses
            }
        }

    }
}
</script>